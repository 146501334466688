
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1XVWeOQKwDcyKh0NwQb3A1 {\n  margin: 0 -10px;\n  font-size: 15px;\n}\n\n@media screen and (max-width: 767px) {\n  ._1XVWeOQKwDcyKh0NwQb3A1 {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n}\n\n._1VtBUe1XybW2n9O2ZljHRP {\n  color: white;\n  margin: 5px 10px;\n  text-decoration: none;\n  opacity: 0.5;\n}\n\n._3GcJkPyGcD9BhSBeqHNnd9 {\n  width: 16px;\n  margin-right: 5px;\n  position: relative;\n  top: 2px;\n}\n\n._1VtBUe1XybW2n9O2ZljHRP:hover {\n  opacity: 1;\n}\n", ""]);
// Exports
exports.locals = {
	"Navigation": "_1XVWeOQKwDcyKh0NwQb3A1",
	"Link": "_1VtBUe1XybW2n9O2ZljHRP",
	"Icon": "_3GcJkPyGcD9BhSBeqHNnd9"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
