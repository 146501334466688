
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3d0QX2D45PrIfcI4FIeCXa {\n  background: #f5d958;\n  padding: 0.25rem;\n}\n\n._1RaTSXdqPaGbJAyBNntAwB {\n  background: #fcf4ca;\n  text-decoration: none;\n  display: flex;\n  flex-direction: column;\n  color: black;\n  transition: background 150ms ease-out;\n}\n\n.tkBuNBrZPML1HlBDSN5bM:hover {\n  background: #ffefa5;\n}\n\n.aXgEw1a65jlSJAvJHHRzp {\n  display: flex;\n  align-items: center;\n  padding: 1rem;\n}\n\n.aXgEw1a65jlSJAvJHHRzp > *:not(:last-child) {\n  margin-right: 1rem;\n}\n\n._1WXRdMMFaQtOjkquCaEUtb {\n  color: #8f8a72;\n}\n\n._32AUdSjWC7KneJt98PRhVF {\n  color: #2c5bf7;\n  font-weight: 600;\n}\n\n._3uuSTZ-_4V9Uy2fZU04A7h {\n  width: 48px;\n  flex-shrink: 0;\n}\n\n._2xISETAwFV_pNy2ZM_mTTE {\n  font-size: 1.05rem;\n  line-height: 1.4;\n  font-weight: 600;\n}\n\n._2rQRSs2PflwiK_c9dBgBP8 {\n  display: flex;\n  justify-content: space-between;\n  border-top: 1px solid #e3dbb6;\n  padding: 0.5rem 1rem;\n}\n\n._17ueKSBb8284U4UOSUWisD {\n  display: flex;\n}\n\n._17ueKSBb8284U4UOSUWisD > *:not(:last-child) {\n  margin-right: 0.5rem;\n}\n\n.xAKHBfblhUKmEAajaZKuY {\n  background: #e3dbb6;\n  color: #444237;\n  padding: 0.25rem 0.4rem;\n  border-radius: 5px;\n  font-size: 0.8rem;\n}\n\n._1fwvSx87t9-uxAwtQ3wTwf {\n  display: flex;\n  background: black;\n  color: white;\n  border: 0;\n  padding: 0.125rem 0.5rem;\n  cursor: pointer;\n  align-items: center;\n}\n\n._1fwvSx87t9-uxAwtQ3wTwf:hover {\n  color: red;\n}\n\n._1fwvSx87t9-uxAwtQ3wTwf > *:not(:last-child) {\n  margin-right: 0.25rem;\n}\n\n._2-NCtkXgaRTYMhdpmF0yqj {\n  width: 1rem;\n}\n\n._2cglQ_yVbXxq4OI6DocZGo {\n  color: #837430;\n  font-weight: 600;\n  text-align: center;\n  display: block;\n  padding: 0.5rem 0;\n  font-size: 0.8rem;\n}\n\n._11uzKQM6ZsANUksHjJhHLd {\n  color: #837430;\n  text-decoration: none;\n}\n\n._11uzKQM6ZsANUksHjJhHLd:hover {\n  color: black;\n  transition: color 150ms ease-out;\n}\n", ""]);
// Exports
exports.locals = {
	"Container": "_3d0QX2D45PrIfcI4FIeCXa",
	"Block": "_1RaTSXdqPaGbJAyBNntAwB",
	"Block-clickable": "tkBuNBrZPML1HlBDSN5bM",
	"Company": "aXgEw1a65jlSJAvJHHRzp",
	"Location": "_1WXRdMMFaQtOjkquCaEUtb",
	"CompanyName": "_32AUdSjWC7KneJt98PRhVF",
	"Logo": "_3uuSTZ-_4V9Uy2fZU04A7h",
	"Header": "_2xISETAwFV_pNy2ZM_mTTE",
	"Buttons": "_2rQRSs2PflwiK_c9dBgBP8",
	"Tags": "_17ueKSBb8284U4UOSUWisD",
	"Tag": "xAKHBfblhUKmEAajaZKuY",
	"NextButton": "_1fwvSx87t9-uxAwtQ3wTwf",
	"NextIcon": "_2-NCtkXgaRTYMhdpmF0yqj",
	"Footer": "_2cglQ_yVbXxq4OI6DocZGo",
	"Link": "_11uzKQM6ZsANUksHjJhHLd"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
