
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2WBAu_d9KtrzrWKaUiOphG {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  margin-bottom: -30px;\n}\n\n._1ZlPc4TMmnKcU98LEK_c6o {\n  display: flex;\n  flex-basis: calc(50% - 50px);\n  margin: 0 50px 30px 0;\n}\n\n@media screen and (max-width: 767px) {\n  ._1ZlPc4TMmnKcU98LEK_c6o {\n    display: flex;\n    flex-basis: 100%;\n    margin: 0 0 30px;\n  }\n}\n\n._2EePanh3bGpGFPfa7fndkf {\n  border: 1px solid #e4e4dd;\n  border-radius: 4px;\n  background-color: white;\n  padding: 16px 15px 15px;\n  flex-grow: 1;\n  position: relative;\n}\n\n._18M0LVOFv950wc4stQ0Hqe {\n  width: 0;\n  height: 0;\n  border-top: 12px solid rgba(0, 0, 0, 0);\n  border-bottom: 12px solid rgba(0, 0, 0, 0);\n  border-right: 12px solid #e4e4dd;\n  position: absolute;\n  top: 16px;\n  left: -12px;\n}\n\n.TIjbrF8rwyXaf0ceYIY3Y {\n  position: relative;\n  top: -11px;\n  left: 1px;\n  width: 0;\n  height: 0;\n  border-top: 11px solid rgba(0, 0, 0, 0);\n  border-bottom: 11px solid rgba(0, 0, 0, 0);\n  border-right: 11px solid #fff;\n}\n\n._3KHVOrk5DU5ZxG_ylWTY8l {\n  flex-basis: 60px;\n  flex-shrink: 0;\n  margin-right: 20px;\n}\n\n._22HaOK8Blxzf7ISPCHvA4s {\n  width: 60px;\n  height: 60px;\n  border-radius: 50%;\n  border: 1px solid #e4e4dd;\n}\n\n._2mBRapaRs9fiTo904WXdAw {\n  font-weight: 600;\n  font-size: 17px;\n  margin-bottom: 5px;\n}\n\n._1foa_xPbX7lwvx9Kcz6bmU {\n  color: #4c193c;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 22px;\n}\n", ""]);
// Exports
exports.locals = {
	"List": "_2WBAu_d9KtrzrWKaUiOphG",
	"Item": "_1ZlPc4TMmnKcU98LEK_c6o",
	"Quote": "_2EePanh3bGpGFPfa7fndkf",
	"QuoteTriangle": "_18M0LVOFv950wc4stQ0Hqe",
	"QuoteTriangleInner": "TIjbrF8rwyXaf0ceYIY3Y",
	"Avatar": "_3KHVOrk5DU5ZxG_ylWTY8l",
	"AvatarImage": "_22HaOK8Blxzf7ISPCHvA4s",
	"Name": "_2mBRapaRs9fiTo904WXdAw",
	"Text": "_1foa_xPbX7lwvx9Kcz6bmU"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
