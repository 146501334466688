
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1iFEJnEr_ndQ3_qWTncOBS {\n  width: 100%;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n}\n\n._1ZceJLyy7dtYSW1A6N2LB_ {\n  display: flex;\n  justify-content: center;\n  align-items: flex-end;\n}\n\n._1QdV_fM8p98aWI3kAz1prh {\n  margin: 0 5px;\n}\n\n._2fSd4XzAxBQzprXC5THCBV {\n  color: #770c56;\n  text-decoration: none;\n  font-size: 14px;\n  padding: 10px 15px;\n  display: block;\n  border-top-right-radius: 3px;\n  border-top-left-radius: 3px;\n  background-color: #efe8df;\n}\n\n._2MVvvKK-jPigE72HiXoT-g {\n  color: white;\n  background-color: #770c56;\n}\n\n._36yQC2f27FT5jaBJavwEas {\n  width: 700px;\n}\n\n@media screen and (max-width: 767px) {\n  ._36yQC2f27FT5jaBJavwEas {\n    width: calc(100vw - 60px);\n  }\n}\n\n._41hzWd4VEbxXQIP8gPgBm {\n  background: #fff8b9;\n  border-radius: 0.2rem;\n  padding: 1rem;\n  color: #4c2f01;\n  margin: 1rem auto 0;\n  max-width: 44rem;\n}\n", ""]);
// Exports
exports.locals = {
	"Container": "_1iFEJnEr_ndQ3_qWTncOBS",
	"Options": "_1ZceJLyy7dtYSW1A6N2LB_",
	"Option": "_1QdV_fM8p98aWI3kAz1prh",
	"OptionLink": "_2fSd4XzAxBQzprXC5THCBV",
	"OptionLink-isCurrent": "_2MVvvKK-jPigE72HiXoT-g",
	"CodeWrapper": "_36yQC2f27FT5jaBJavwEas",
	"Warning": "_41hzWd4VEbxXQIP8gPgBm"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
