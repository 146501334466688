
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._27LCZfpk09y4CLOkrPmst6 {\n  width: 100%;\n  color: white;\n  background: #5a0530;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: top center;\n  overflow-y: auto;\n}\n\n.xTKY4I48xlt94hO0yQs4K {\n  background: linear-gradient(rgba(118, 10, 61, 0.7), #4c042b);\n  padding: 4rem 1rem;\n  align-items: center;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n}\n\n._3ttQ-sSi-fAPTRaiMcbEA7 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 33px;\n}\n\n._1-u1HoWRjwuLyzwnxeyoAh {\n  width: 40px;\n}\n\n.MRO_EMvc-OyI3Eh0IGfKR {\n  font-size: 35px;\n  color: #fff;\n  font-weight: 400;\n  margin-left: 10px;\n  position: relative;\n  top: -1px;\n}\n\n._3-RqDHPtDPNmwgqPCdCzOv {\n  text-align: center;\n  color: #fbd7a1;\n  font-size: 20px;\n  margin-top: 2rem;\n  font-weight: 300;\n}\n\n._3XGIcHrFlUYnXJX4Qiqu0Q {\n  font-size: 16px;\n  line-height: 22px;\n  font-weight: 200;\n  text-align: center;\n  margin-top: 1rem;\n  color: #ffe9c9;\n  max-width: 500px;\n}\n\n._1Rv7lO61waGv_OMy8B7K-- {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  margin: 2rem 0;\n}\n\n._3EL8yIp8swvlCoB0u8x0Ng {\n  display: block;\n  background: #8c1b54;\n  text-align: center;\n  text-decoration: none;\n  color: #fff;\n  border-radius: 3px;\n  font-size: 1rem;\n  padding: 1rem;\n  width: 12rem;\n}\n", ""]);
// Exports
exports.locals = {
	"Outer": "_27LCZfpk09y4CLOkrPmst6",
	"Inner": "xTKY4I48xlt94hO0yQs4K",
	"Logo": "_3ttQ-sSi-fAPTRaiMcbEA7",
	"LogoImage": "_1-u1HoWRjwuLyzwnxeyoAh",
	"LogoName": "MRO_EMvc-OyI3Eh0IGfKR",
	"Header": "_3-RqDHPtDPNmwgqPCdCzOv",
	"Text": "_3XGIcHrFlUYnXJX4Qiqu0Q",
	"GettingStarted": "_1Rv7lO61waGv_OMy8B7K--",
	"GettingStartedLink": "_3EL8yIp8swvlCoB0u8x0Ng"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
