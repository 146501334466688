
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrU5ovct0WDj3yyfvps0R {\n  list-style: none;\n  display: flex;\n  justify-content: left;\n  margin: 0 !important;\n}\n\n._3AVBZWhO6404xKr5FNpTTW {\n  list-style: none;\n  margin-right: 10px;\n}\n\n._2a9byu2loYXfMnEm7sHyy {\n  color: #4a3142;\n  text-decoration: none;\n  font-size: 13px;\n  padding: 4px 8px;\n  display: block;\n  border-top-right-radius: 3px;\n  border-top-left-radius: 3px;\n  background-color: #faf6f0;\n}\n\n.ZKZfbxPc6hDwfZwugv9MK {\n  color: #000;\n  background-color: #e8d6e3;\n}\n", ""]);
// Exports
exports.locals = {
	"Options": "OrU5ovct0WDj3yyfvps0R",
	"Option": "_3AVBZWhO6404xKr5FNpTTW",
	"OptionLink": "_2a9byu2loYXfMnEm7sHyy",
	"OptionLink-isCurrent": "ZKZfbxPc6hDwfZwugv9MK"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
