
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1hNOOsvChGZ2Lh4tg220dc {\n  width: 100%;\n  height: 100%;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n._2gRIWWeW7YW9kSo4XnwlpW {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"Screen": "_1hNOOsvChGZ2Lh4tg220dc",
	"Content": "_2gRIWWeW7YW9kSo4XnwlpW"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
