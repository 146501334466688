
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".yAUgfJ66eQlH6mKxDoHlF {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border-bottom: 1px solid #e4e4dd;\n}\n\n.yAUgfJ66eQlH6mKxDoHlF:nth-child(odd) {\n  background-color: #fffdfa;\n}\n\n.yAUgfJ66eQlH6mKxDoHlF:nth-child(even) {\n  background-color: #fffbf5;\n}\n\n.yAUgfJ66eQlH6mKxDoHlF:last-child {\n  border-bottom: none;\n}\n\n._2kOlaa0CSQzsl0HuJ8bWdR {\n  max-width: 1200px;\n  padding: 30px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.YLGiStwiDshSvUqGVgTm- {\n  color: #770c56;\n  font-weight: 600;\n  font-size: 20px;\n}\n\n._1nEknJD5GGnhW4NCAPSYoY {\n  color: #770c56;\n  margin-top: 0.8rem;\n  font-size: 1rem;\n}\n\n._1FZZIKkOYQpBQ_-N9PWF4P {\n  display: flex;\n  align-items: center;\n}\n._1FZZIKkOYQpBQ_-N9PWF4P :not(:last-child) {\n  margin-right: 1rem;\n}\n\n._1fvZcNThvc2zjlw3Q4CK1Z {\n  color: #8c1b54;\n  margin-top: 20px;\n}\n\n._2hirGB8rZbx-rvI7uKuJcJ {\n  margin-top: 30px;\n  display: block;\n  text-align: center;\n  text-decoration: none;\n  border-radius: 3px;\n  font-size: 16px;\n  padding: 15px 30px;\n}\n._19SBemr7ZXThvOhIuTPoDN {\n  background: #8c1b54;\n  color: #fff;\n  border: 1px solid #8c1b54;\n}\n._1Sjj4PnHhaYkVZO6ZXNbmP {\n  background: transparent;\n  color: #8c1b54;\n  border: 1px solid #8c1b54;\n}\n\n._1zqTQnPG_nSkM78NNJyaLy {\n  color: #862d5b;\n  text-decoration: none;\n}\n\n._3DEbVTPIt1JankH8fdNgyF {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n._3DEbVTPIt1JankH8fdNgyF:not(:first-child) {\n  margin-top: 30px;\n}\n\n._2GkjTchwA2RC2hJReY9rj6 {\n  font-size: 16px;\n  line-height: 22px;\n  text-align: center;\n  color: #4c193c;\n  max-width: 500px;\n}\n\n._2GkjTchwA2RC2hJReY9rj6 p,\n._2GkjTchwA2RC2hJReY9rj6 img {\n  margin-bottom: 1rem;\n}\n\n._2GkjTchwA2RC2hJReY9rj6 p:last-child {\n  margin-bottom: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"Block": "yAUgfJ66eQlH6mKxDoHlF",
	"InnerContainer": "_2kOlaa0CSQzsl0HuJ8bWdR",
	"Header": "YLGiStwiDshSvUqGVgTm-",
	"SubHeader": "_1nEknJD5GGnhW4NCAPSYoY",
	"Actions": "_1FZZIKkOYQpBQ_-N9PWF4P",
	"Action": "_1fvZcNThvc2zjlw3Q4CK1Z",
	"Button": "_2hirGB8rZbx-rvI7uKuJcJ",
	"Button-type-primary": "_19SBemr7ZXThvOhIuTPoDN",
	"Button-type-secondary": "_1Sjj4PnHhaYkVZO6ZXNbmP",
	"Link": "_1zqTQnPG_nSkM78NNJyaLy",
	"Content": "_3DEbVTPIt1JankH8fdNgyF",
	"Text": "_2GkjTchwA2RC2hJReY9rj6"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
