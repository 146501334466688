
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".C700O97MPwbWHgbUttwkM {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n._1Oy_qeP0b3Es_t4icQ4GW2:not(:last-child) {\n  margin-bottom: 0.5rem;\n}\n\n._3VX4KKdvqIWCX0WWh2bMVK {\n  margin-top: 1rem;\n  font-size: 0.8rem;\n}\n\n.C700O97MPwbWHgbUttwkM a {\n  color: #a07d8f;\n}\n", ""]);
// Exports
exports.locals = {
	"Container": "C700O97MPwbWHgbUttwkM",
	"Row": "_1Oy_qeP0b3Es_t4icQ4GW2",
	"Row-isLicense": "_3VX4KKdvqIWCX0WWh2bMVK"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
