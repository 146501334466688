
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".zemjhu-W4cSz_CYxeiGig {\n  display: none;\n  margin-left: 7px;\n  text-decoration: none;\n  opacity: 0.3;\n}\n\n.zemjhu-W4cSz_CYxeiGig:hover {\n  opacity: 0.6;\n}\n\nh1:hover .zemjhu-W4cSz_CYxeiGig,\nh2:hover .zemjhu-W4cSz_CYxeiGig,\nh3:hover .zemjhu-W4cSz_CYxeiGig,\nh4:hover .zemjhu-W4cSz_CYxeiGig,\nh5:hover .zemjhu-W4cSz_CYxeiGig,\nh6:hover .zemjhu-W4cSz_CYxeiGig {\n  display: inline;\n}\n", ""]);
// Exports
exports.locals = {
	"DocHeaderLink": "zemjhu-W4cSz_CYxeiGig"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
