
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._15XFot58BUnEG-neOLV-Xq {\n  color: #4a3142;\n  margin-top: 15px;\n  font-size: 17px;\n  display: flex;\n  flex-wrap: wrap;\n  margin-bottom: -20px;\n  justify-content: center;\n}\n\n._1B2b_ie6vpCWxoZt0LeQFy {\n  margin-top: 20px;\n  margin: 0 20px 20px 0;\n  flex-shrink: 0;\n}\n\n._2zGYGFOGmHWuG5fKcIGgBh {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n._1B2b_ie6vpCWxoZt0LeQFy:last-child {\n  margin-bottom: 0;\n}\n\n._2n1He32y0RRsIpwGZ20Wid {\n  width: 60px;\n  height: 60px;\n  border-radius: 50%;\n}\n\n._2KdulHEreaRmnIVjl3bGui {\n  font-size: 14px;\n  margin: 5px 0 5px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"List": "_15XFot58BUnEG-neOLV-Xq",
	"Item": "_1B2b_ie6vpCWxoZt0LeQFy",
	"LinkContent": "_2zGYGFOGmHWuG5fKcIGgBh",
	"Avatar": "_2n1He32y0RRsIpwGZ20Wid",
	"Name": "_2KdulHEreaRmnIVjl3bGui"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
