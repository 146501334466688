
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".przJlIkP6k4qEVJifVf4 {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  flex-direction: column;\n  align-items: stretch;\n  overflow: hidden;\n}\n\n._3TPvw5NCDxzCyoz4R1T6y- {\n  flex: none;\n  height: 2rem;\n}\n\n._2UbTexLxtHLiSgL0EY8Nuh {\n  flex: auto;\n  display: flex;\n  flex-direction: row;\n  align-items: stretch;\n  min-height: 0;\n  position: relative;\n}\n\n._1yMB9rBWvB8y-Oko0xN3Sw {\n  flex: none;\n  width: 25rem;\n  max-width: 100%;\n}\n\n@media screen and (max-width: 767px) {\n  ._1yMB9rBWvB8y-Oko0xN3Sw {\n    display: none;\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    z-index: 10;\n  }\n\n  ._1T7uIyrVTthu6g1IxPcZdM {\n    display: block;\n  }\n}\n\n._1pIXct2usE-y6W_Bd7TiyU {\n  height: auto;\n  min-height: 100%;\n  flex: auto;\n  overflow-y: auto;\n}\n\n@media screen and (max-width: 767px) {\n  ._12Jd8oVGiEjxsU3kFCSsT4 {\n    overflow-y: hidden;\n  }\n\n  ._1pIXct2usE-y6W_Bd7TiyU:after {\n    display: none;\n    content: '';\n    background-color: grey;\n    opacity: 0.5;\n    height: 100%;\n    width: 100%;\n    left: 0;\n    top: 0;\n    position: absolute;\n  }\n\n  ._12Jd8oVGiEjxsU3kFCSsT4:after {\n    display: block;\n  }\n}\n\n._3YrBUzFMDstWYiY2Az1KA2 {\n  padding: 30px;\n}\n", ""]);
// Exports
exports.locals = {
	"Screen": "przJlIkP6k4qEVJifVf4",
	"NavBarContainer": "_3TPvw5NCDxzCyoz4R1T6y-",
	"Content": "_2UbTexLxtHLiSgL0EY8Nuh",
	"FinderContainer": "_1yMB9rBWvB8y-Oko0xN3Sw",
	"FinderContainer-menuOpen": "_1T7uIyrVTthu6g1IxPcZdM",
	"DocContainer": "_1pIXct2usE-y6W_Bd7TiyU",
	"DocContainer-menuOpen": "_12Jd8oVGiEjxsU3kFCSsT4",
	"Loading": "_3YrBUzFMDstWYiY2Az1KA2"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
