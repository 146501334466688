
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ntb6LOidomppIh3OjCEdT {\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  color: #4c193c;\n  margin-bottom: 25px;\n}\n\n._2PP35rL4yn_D1e7wU5JAFQ {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 1rem;\n  flex-wrap: wrap;\n  margin-right: -20px;\n}\n\n._1njJgC7elNtpLdVyevOTQf {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 0 20px 20px 0;\n}\n\n._2gpz26gLPV6ve4qV0Q_yk6 {\n  width: 60px;\n  height: 60px;\n  border-radius: 50%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.MkpgFdKgY7Ml8DsrWL1Ha {\n  height: 150px;\n  width: 150px;\n}\n\n._2vJhf26YGsFcAh4HCWAns0 {\n  height: 120px;\n  width: 120px;\n}\n\n._1qrUCq--mLReIO1esQWD0z {\n  height: 80px;\n  width: 80px;\n}\n\n._2NQQZ_sHF-AfcLG7FwW-O5 {\n  width: 100%;\n}\n\n._2wTHMnhidplRddDTIBrMa6 {\n  background-color: grey;\n  height: 7rem;\n  width: 7rem;\n}\n\n._37GRS1giOv1R3h6KmSyblN {\n  font-size: 14px;\n  margin: 5px 0 5px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"Subheader": "_3ntb6LOidomppIh3OjCEdT",
	"List": "_2PP35rL4yn_D1e7wU5JAFQ",
	"Item": "_1njJgC7elNtpLdVyevOTQf",
	"ItemImageContainer": "_2gpz26gLPV6ve4qV0Q_yk6",
	"ItemImageContainer-tier-gold": "MkpgFdKgY7Ml8DsrWL1Ha",
	"ItemImageContainer-tier-silver": "_2vJhf26YGsFcAh4HCWAns0",
	"ItemImageContainer-tier-bronze": "_1qrUCq--mLReIO1esQWD0z",
	"ItemImage": "_2NQQZ_sHF-AfcLG7FwW-O5",
	"ItemImagePlaceholder": "_2wTHMnhidplRddDTIBrMa6",
	"ItemName": "_37GRS1giOv1R3h6KmSyblN"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
