
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UvTFkNOjRryI2qrtH-loG {\n  padding: 1rem;\n}\n\n._1lSdj_d2DoMtmgC0syl9F2 {\n  display: flex;\n  align-items: center;\n  margin: 0.5rem 0 0;\n}\n\n._2Hs92mP7lPzk0uMC7H4ofV {\n  flex: auto;\n  height: 2rem;\n  background: #f9f6e3;\n  font-size: 1rem;\n  padding: 0 0.5rem;\n  border: 0;\n  border-radius: 0;\n  color: black;\n  margin-right: 0.5rem;\n  border: 1px solid black;\n}\n\n._2Ph3-Xbqq6GnGo3CXJq9K {\n  flex: none;\n  height: 2rem;\n  font-size: 1rem;\n  display: block;\n  background: black;\n  color: white;\n  border: 0;\n  padding: 0 1rem;\n  cursor: pointer;\n  align-items: center;\n}\n\n._2Ph3-Xbqq6GnGo3CXJq9K:hover {\n  color: red;\n}\n\n._1KiTGZknFnjmanQZOCC7JF {\n  width: 24px;\n  margin-right: 5px;\n  position: relative;\n  top: 6px;\n}\n\n._3Lcyvlw0XUx3Gum0nDlCtM {\n  color: black;\n  text-decoration: none;\n}\n", ""]);
// Exports
exports.locals = {
	"BlockContent": "UvTFkNOjRryI2qrtH-loG",
	"Form": "_1lSdj_d2DoMtmgC0syl9F2",
	"Input": "_2Hs92mP7lPzk0uMC7H4ofV",
	"SubscribeButton": "_2Ph3-Xbqq6GnGo3CXJq9K",
	"TwitterIcon": "_1KiTGZknFnjmanQZOCC7JF",
	"TwitterLink": "_3Lcyvlw0XUx3Gum0nDlCtM"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
