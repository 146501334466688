
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._196Qox0Wwj1MEbUTTGDfI4 {\n  font-style: italic;\n}\n\n.Dvkz1-mNTwju9P6ZMnFFu {\n  font-weight: bold;\n  margin-top: 10px;\n  margin-bottom: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"OptionalLabel": "_196Qox0Wwj1MEbUTTGDfI4",
	"PropsLabel": "Dvkz1-mNTwju9P6ZMnFFu"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
