
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IBmNRx5wVphZd_An13O_u {\n  padding: 0.5rem;\n  background: #39065a;\n  color: #fff6f6;\n  display: flex;\n  align-items: flex-start;\n  text-decoration: none;\n  transition: background 150ms ease-out;\n}\n\n._1C-4ZaNVcMWzvW1U7zalOq {\n  background: #9a0f98;\n  padding: 0.25rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.KTGhGQ_gtLiHjQ4oeG745 {\n  color: white;\n  font-weight: 600;\n}\n\n.Eh3rD3E9D-1LNHCF95Bbe {\n  background: transparent;\n  color: white;\n  border: 1px solid white;\n  border-radius: 0.25rem;\n  cursor: pointer;\n}\n\n.Eh3rD3E9D-1LNHCF95Bbe:hover {\n  color: #9a0f98;\n  background: white;\n}\n\n.IBmNRx5wVphZd_An13O_u:hover {\n  background: #6a0572;\n}\n\n.IBmNRx5wVphZd_An13O_u > *:not(:last-child) {\n  margin-right: 0.75rem;\n}\n\n.pR7cnaqRD4CEWFXpLOCKR {\n  width: 6rem;\n  flex-shrink: 0;\n}\n\n.woGM5OIQlaFCoQLeCwAND {\n  line-height: 1.2;\n}\n\n.woGM5OIQlaFCoQLeCwAND > *:not(:last-child) {\n  margin-bottom: 0.5rem;\n}\n\n._3vtw9YVy0P3RBWxiecHOas > *:not(:last-child) {\n  margin-bottom: 0.125rem;\n}\n\n._23Enra3YDrIF2ol1lFPyuR {\n  font-weight: 600;\n  font-size: 1.25rem;\n  color: #ead7af;\n}\n\n.IBmNRx5wVphZd_An13O_u:hover ._23Enra3YDrIF2ol1lFPyuR {\n  text-decoration: underline;\n}\n\n._2_42Vpf6mXAskUxPiDdbBp {\n  line-height: 1.4;\n  font-size: 0.9rem;\n  color: #fdf4dc;\n}\n\n._1cOlJ33uQxprEmJMxZqDtk {\n  background: #ea0599;\n  padding: 0.25rem 0.5rem;\n  display: inline-block;\n  color: white;\n  font-size: 1rem;\n  font-weight: 500;\n  border-radius: 0.25rem;\n  transition: background 150ms ease-out;\n  border: none;\n  cursor: pointer;\n}\n\n.IBmNRx5wVphZd_An13O_u:hover ._1cOlJ33uQxprEmJMxZqDtk {\n  background: #ff00a5;\n}\n", ""]);
// Exports
exports.locals = {
	"Container": "IBmNRx5wVphZd_An13O_u",
	"Badge": "_1C-4ZaNVcMWzvW1U7zalOq",
	"BadgeLabel": "KTGhGQ_gtLiHjQ4oeG745",
	"BadgeNext": "Eh3rD3E9D-1LNHCF95Bbe",
	"Cover": "pR7cnaqRD4CEWFXpLOCKR",
	"Text": "woGM5OIQlaFCoQLeCwAND",
	"Header": "_3vtw9YVy0P3RBWxiecHOas",
	"Title": "_23Enra3YDrIF2ol1lFPyuR",
	"Description": "_2_42Vpf6mXAskUxPiDdbBp",
	"Link": "_1cOlJ33uQxprEmJMxZqDtk"
};
module.exports = exports;

var convert = require('@nyancss/css-modules/convert').default
var wrapper = require('@nyancss/react/wrapper/preact').default
var intoLocals = Array.isArray(module.exports)
var classNames = (intoLocals ? exports.locals : module.exports) || {}
var obj = wrapper(convert(classNames))
obj.classNames = classNames
if (intoLocals) {
  exports.locals = obj
} else {
  module.exports = obj
}
